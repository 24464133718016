import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Consumer } from 'store/createContext';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Logo from 'images/logo.svg';
import Link from 'gatsby-link';
import Container from '@material-ui/core/Container';
import IconSml from 'images/icon-sml-reverse.svg';
import linkResolver from 'helpers/linkResolver';

const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 500;
  display: flex;
`;

const MenuQuery = graphql`
  query {
    prismicGlobalLayout {
      data {
        main_menu {
          link {
            id
            type
            uid
            url
          }
          link_title {
            html
            raw
            text
          }
        }
      }
    }
  }
`;

function GroupMenu() {
  return (
    <StaticQuery
      query={`${MenuQuery}`}
      render={data => (
        <Wrapper>
          <Container maxWidth={false}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={5}
              >
                <Consumer>
                  {({ toggleGroupMenu }) => (
                    <button onClick={toggleGroupMenu} className="standard-btn">
                      <CloseIcon />
                    </button>
                  )}
                </Consumer>
                <div>
                  <Link to="/">
                    <Logo className="brand" />
                  </Link>
                </div>
                <div>
                  <CloseIcon className="visibility-hidden" />
                </div>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Box>
                  <a
                    href="https://realestatecapitalholdings.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    <Typography
                      component="span"
                      variant="h3"
                      color="textPrimary"
                    >
                      Real
                      <br />
                      Estate
                    </Typography>
                  </a>
                </Box>
                <Box mb={6}>
                  <a
                    href="https://realestatecapitalholdings.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                  >
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                      className="text-uppercase"
                    >
                      Capital
                      <br />
                      Holdings
                    </Typography>
                  </a>
                </Box>
                <Box mb={1}>
                  <a
                    href="https://www.hayfieldhomes.co.uk/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    <Typography variant="h3" color="textPrimary">
                      HAYFIELD
                    </Typography>
                  </a>
                </Box>
                <Box mb={1}>
                  <a
                    href="https://livingspacehousing.co.uk/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    <Typography variant="h3" color="textPrimary">
                      LIVING SPACE
                    </Typography>
                  </a>
                </Box>
                <Box mb={1}>
                  <a
                    href="https://www.gateway-land.co.uk/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    <Typography variant="h3" color="textPrimary">
                      GATEWAY
                    </Typography>
                  </a>
                </Box>
                <Box mb={1}>
                  <a
                    href="https://www.merrion-holdings.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    <Typography variant="h3" color="textPrimary">
                      Merrion
                    </Typography>
                  </a>
                </Box>
                <Box mb={1}>
                  <a
                    href="https://www.verdanthomes.co.uk/"
                    target="_blank"
                    rel="noreferrer"
                    className="footer-link"
                  >
                    <Typography variant="h3" color="textPrimary">
                      Verdant
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                py={5}
                width="100%"
              >
                <IconSml className="icon-sml" />
              </Box>
            </Box>
          </Container>
        </Wrapper>
      )}
    />
  );
}

export default GroupMenu;

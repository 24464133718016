import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import linkResolver from 'helpers/linkResolver';
import Link from 'gatsby-link';

const MenuQuery = graphql`
  query {
    prismicGlobalLayout {
      data {
        footer_menu {
          link {
            url
            uid
            type
            id
          }
          link_title {
            html
            raw
            text
          }
        }
      }
    }
  }
`;

function Footer() {
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);
  return (
    <StaticQuery
      query={`${MenuQuery}`}
      render={data => (
        <Box component="footer" bgcolor="primary.main" py={10} mt={20}>
          <Container maxWidth="xl">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      component="p"
                      variant="h3"
                      color="textSecondary"
                      gutterBottom
                    >
                      GET IN TOUCH
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textSecondary"
                    >
                      If you think your land may have development potential and
                      would like to see its value increase by working with Terra
                      then contact us
                    </Typography>
                  </Grid>
                </Grid>
                <Box py={10}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="p"
                        variant="caption"
                        color="textSecondary"
                      >
                        +44 (0)121 704 1134{' '}
                      </Typography>
                      <Typography
                        component="p"
                        variant="caption"
                        color="textSecondary"
                      >
                        INFO@TERRASTRATEGIC.CO.UK{' '}
                      </Typography>
                      <Typography
                        component="p"
                        variant="caption"
                        color="textSecondary"
                      >
                        Hayfield House, Arleston Way, Shirley, Solihull, B90 4LH{' '}
                      </Typography>
                      <Typography
                        component="p"
                        variant="caption"
                        color="textSecondary"
                      >
                        TERRA IS A TRADING NAME FOR CSL STRATEGIC LTD
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Typography
                        component="p"
                        variant="caption"
                        color="textSecondary"
                      >
                        FOLLOW US
                      </Typography>
                      <Box display="flex" flexDirection="row" py={2}>
                        <Box pr={1}>
                          <a
                            href="https://www.linkedin.com/company/53113664/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon className="social-icon" />
                          </a>
                        </Box>
                        <Box pr={1} className="visibility-hidden">
                          <a
                            href="http://www.terrastrategic.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FacebookIcon className="social-icon" />
                          </a>
                        </Box>
                        <Box pr={1} className="visibility-hidden">
                          <a
                            href="http://www.terrastrategic.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <TwitterIcon className="social-icon" />
                          </a>
                        </Box>
                      </Box>
                      <Box mb={2}>
                        <Typography
                          component="p"
                          variant="caption"
                          color="textSecondary"
                        >
                          Copyright &copy; Terra {date}. All rights reserved.
                        </Typography>
                      </Box>
                      <div className="spaced-grid-wrapper">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Box>
                              {data.prismicGlobalLayout.data.footer_menu.map(
                                (item, key) => {
                                  return (
                                    <div key={key}>
                                      <Link
                                        to={linkResolver(item.link)}
                                        className="no-underline"
                                      >
                                        <Typography
                                          component="span"
                                          variant="caption"
                                          color="textSecondary"
                                        >
                                          {item.link_title.text}
                                        </Typography>
                                      </Link>
                                    </div>
                                  );
                                }
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  display="flex"
                  justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                  height="100%"
                  mt={{ xs: 6, md: 0 }}
                >
                  <Box className="footer-border-left" pl={{ xs: 0, md: 20 }}>
                    <Box>
                      <a
                        href="https://realestatecapitalholdings.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography
                          component="span"
                          variant="h3"
                          color="textSecondary"
                        >
                          Real
                          <br />
                          Estate
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={6}>
                      <a
                        href="https://realestatecapitalholdings.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-link"
                      >
                        <Typography
                          component="span"
                          variant="body2"
                          color="textSecondary"
                          className="text-uppercase"
                        >
                          Capital
                          <br />
                          Holdings
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://www.hayfieldhomes.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          HAYFIELD
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://livingspacehousing.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          LIVING SPACE
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://www.gateway-land.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          Gateway
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://www.merrion-holdings.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          Merrion
                        </Typography>
                      </a>
                    </Box>
                    <Box mb={1}>
                      <a
                        href="https://www.verdanthomes.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        className="footer-link"
                      >
                        <Typography variant="h3" color="textSecondary">
                          Verdant
                        </Typography>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    />
  );
}

export default Footer;

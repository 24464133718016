import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import MainNavigation from 'components/header/MainNavigation';
import GroupMenu from 'components/header/GroupMenu';
import { Consumer } from 'store/createContext';
import scrollTo from 'gatsby-plugin-smoothscroll';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withStyles } from '@material-ui/styles';
import Link from 'gatsby-link';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  blur: {
    transition: 'all 0.1s ease-in-out',
    '&.active': {
      filter: 'blur(5px)',
      transform: 'scale(0.99)',
    },
  },
  overlay: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.6)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    '&.active': {
      opacity: 1,
      zIndex: 1,
    },
  },
  topLink: {
    position: 'fixed',
    bottom: '-30px',
    right: '30px',
    zIndex: 10,
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: 0,
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    outline: 0,
    opacity: 0,
    '&.show': {
      opacity: 1,
      bottom: '30px',
    },
    '&:hover': {
      boxShadow: '1px 1px 20px -6px rgba(0,0,0,0.75)',
    },
  },
  icon: {
    color: 'white',
  },
});

function Layout(props) {
  const { children, data, classes } = props;
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (typeof window !== 'undefined') {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    }
  };

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.addEventListener('scroll', checkScrollTop);
    return () => {
      // remove resize listener
      typeof window !== 'undefined' &&
        window.addEventListener('scroll', checkScrollTop);
    };
  }, []);
  return (
    <Consumer>
      {({ menuOpen, groupMenu }) => (
        <div>
          <GlobalStyle />
          <Head />
          <Helmet>
            <body
              className={`${menuOpen || groupMenu ? 'menuOpen' : 'menuClosed'}`}
            />
          </Helmet>
          {menuOpen && <MainNavigation menu={menuOpen} />}
          {groupMenu && <GroupMenu menu={groupMenu} />}
          <Header title={data.site.siteMetadata.siteTitle} />
          {children}
          <Footer />
          <button
            aria-label="Scroll to top"
            className={`${classes.topLink} ${showScroll ? 'show' : 'hide'}`}
            onClick={() => scrollTo('#___gatsby')}
          >
            <ExpandLessIcon className="icon-white" />
          </button>
          <CookieConsent
            location="bottom"
            declineButtonText="Decline"
            buttonText="Accept"
            cookieName="Terra"
            style={{ background: '#5A6364' }}
            buttonStyle={{
              color: '#5A6364',
              fontSize: '13px',
              backgroundColor: '#fff',
            }}
            declineButtonStyle={{
              color: '#5A6364',
              fontSize: '13px',
              backgroundColor: '#fff',
            }}
            expires={150}
            enableDeclineButton
          >
            <Typography color="secondary" component="span" variant="caption">
              This website uses cookies to enhance the user experience.{' '}
              <Typography
                component={Link}
                to="/cookie-policy"
                color="secondary"
                variant="caption"
              >
                Click here to find out more
              </Typography>
            </Typography>
          </CookieConsent>
        </div>
      )}
    </Consumer>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(LayoutWithQuery);
